import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-inicio-apontamento-no-turno',
  templateUrl: './inicio-apontamento-no-turno.component.html',
  styleUrls: ['./inicio-apontamento-no-turno.component.scss']
})
export class InicioApontamentoNoTurnoComponent implements OnInit {

  public ordens: any;
  public query: string;

  constructor(
    private ajax: AjaxService,
    private util: UtilService,
    private login: LoginService
  ) { }

  ngOnInit() {
    this.buscarProgramacao();
  }

  buscarProgramacao() {
    this.login.getUser();
    this.util.showLoading();
    var url = API + 'Programacao/Programacao?status=1&turno='+this.login.user.turnoCodigo;
    this.ajax.get(url)
      .subscribe((ordens: ResponseNet) => {

        debugger
        console.log(ordens);
        this.util.closeLoading();
        this.ordens = ordens.data;
        //this.formasPagamento = pagamento.data;

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  /*buscaOP() {
    this.util.showLoading();
    var url = API + 'Op/Op?ID_STATUS_OP=1';
    this.ajax.get(url)
      .subscribe((ordens: ResponseNet) => {

        console.log(ordens);
        this.util.closeLoading();
        this.ordens = ordens.data;
        //this.formasPagamento = pagamento.data;

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }*/


  reprovar(op){
    console.log(op);

    Swal.fire({
      title: 'Atenção?',
      text: 'Deseja Realmente Excluir essa Programação.',
      type: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
    }).then((willDelete) => {
        debugger
        if (willDelete.dismiss) {
          //Swal.fire('', 'Your imaginary file is safe!', 'error');
        } else {
          console.log("vai deletar");
          this.reprovarProgramacao(op);
        }
      });

  }

  reprovarProgramacao(op){
    debugger
    let url = API + 'MontagemTurno/MontagemTurno/exluirApontamento';
     let dados = {      
       "ID_PROGRAMACAO": op.programacao_id
     };
     this.util.showLoading();
    this.ajax.post(url, dados)
      .subscribe((op: ResponseNet) => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "OP " + op.data.c2_NUM + " incluida com sucesso!");
        this.buscarProgramacao();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.error);
      });
  }


  iniciar(op) {

    console.log(op);
    debugger
    let url = API + 'Op/Op/CriarApontamento';
   /* let dados = {
      "id_op": op.id,
      "id_status": 2,
      "maquina_id": op.maquina_id,
      "produto_id": op.produto_id,
      "quantidade": op.quantidade
    };*/
    this.login.getUser();
    let dados = op;
    dados.USUARIO_AD = this.login.user.login;
    dados.status = 2;
    this.util.showLoading();
    this.ajax.post(url, dados)
      .subscribe((op: ResponseNet) => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "Programacao Exluida com sucesso!");
        this.buscarProgramacao();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.error);
      });
    //debugger
    //this.util.toasterShowSuccess("Titulo", "galo");
    /*Swal.fire({
      title: 'Atenção?',
      text: 'Você tem certeza que gostaria de Iniciar essa Ordem?',
      type: 'warning',
      showCloseButton: true,
      showCancelButton: true
    }).then((willDelete) => {
        if (willDelete.dismiss) {
          //Swal.fire('', 'Your imaginary file is safe!', 'error');
        } else {
          //Swal.fire('', 'Poof! Your imaginary file has been deleted!', 'success');
        }
      });*/
  }

}
