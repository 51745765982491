import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {


  login: string;
  senha: string;
  navigateTo: string;
  loading: boolean;

  constructor(
    private fb: FormBuilder,
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private loginService: LoginService,
    private ajax: AjaxService,
    private util: UtilService
    
  ) { }

  ngOnInit() {
    this.navigateTo = this.activatedRoute.snapshot.params['to'] || btoa('/');
    //this.login = "lumago.terceiro";
    //this.senha = "Antares@123";
    //this.login = "teste.lumago";
    //this.senha = "Antares@123";
    this.login = "";
    this.senha = "";
  }



    
  Entrar() {

    let dados = {
      "login": this.login,
      "senha": this.senha 
   };

   console.log(dados);
   this.util.showLoading();
   this.loading = true;
   var url = API + 'Login/Login';
   this.ajax.post(url, dados)
     .subscribe((user) => {

      if(user.valido){

        debugger
        this.loginService.setUser(user);
        this.util.closeLoading();
        this.loading = false;
        this.util.toasterShowSuccess("Sucesso", user.mensagem);
        this.router.navigate(['/home']);

      }else{
        this.util.closeLoading();
        this.loading = false;
        this.util.toasterShowError("Atenção", user.mensagem);
      }   

     }, err => {
       debugger
       this.util.closeLoading();
       this.loading = false;
       this.util.toasterShowError("Atenção", err.error);
     });

  }
   
 
   isValidEmail(email) {
     var re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
     return re.test(String(email).toLowerCase());
   }
 
   isValidTelefone(telefone){
     var re = /^(?:(55\d{2})|\d{2})[6-9]\d{8}$/
     return re.test(String(telefone).toLowerCase())
   }

}
