import { Component, OnInit } from '@angular/core';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manutencao-encerramento',
  templateUrl: './manutencao-encerramento.component.html',
  styleUrls: ['./manutencao-encerramento.component.scss']
})
export class ManutencaoEncerramentoComponent implements OnInit {

  public apontamentos: any;
  public query: string;


  constructor(
    private ajax: AjaxService,
    private util: UtilService,
    private login: LoginService
  ) { }

  ngOnInit() {
    this.carreagarApontamentos();
  }

  carreagarApontamentos() {
    this.login.getUser();
    this.util.showLoading();
    var url = API + 'MontagemTurno/MontagemTurno/BuscarApontamentos?ID_STATUS_MONTAGEM_TURNO=2&R6_TURNO='+this.login.user.turnoCodigo;;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.apontamentos = apt.data;

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  encerrarApontamento(montagem_turno) {

    debugger
    if(montagem_turno.fim_producao != null){    

    Swal.fire({
      title: 'Atenção?',
      text: 'Gostaria de Finalizar esta Ordem? Caso haja alguma Observação escreva abaixo!',
      type: 'warning',
      input: 'text',
      showCloseButton: true,
      showCancelButton: true
    }).then((result) => {
      if (result.dismiss) {

      } else {

        debugger
        let url = API + 'MontagemTurno/MontagemTurno/encerrarApontamento';
        let dados = {
          "ID_MONTAGEM_TURNO": montagem_turno.id_montagem_turno,
          "OBSERVACAO": result.value,
          "ID_PROGRAMACAO": montagem_turno.id_programacao,
          "fim_producao": montagem_turno.fim_producao
        };

        this.util.showLoading();
        this.ajax.post(url, dados)
          .subscribe((op: ResponseNet) => {

            this.util.closeLoading();
            this.util.toasterShowSuccess("Sucesso", "OP Ecerrada com sucesso!");
            this.carreagarApontamentos();

          }, err => {
            debugger
            this.util.closeLoading();
            this.util.toasterShowError("Atenção", err.error);
          });

      }
    });

  }else{
    this.util.toasterShowError("Atenção", "Preencha o campo Fim OP");
  }

    /*let url = API + 'MontagemTurno/MontagemTurno/encerrarApontamento';
    let dados = {
      "ID_MONTAGEM_TURNO": montagem_turno.id_montagem_turno
    };

    
    this.util.showLoading();
    this.ajax.post(url, dados)
      .subscribe((op: ResponseNet) => {

        this.util.closeLoading();
        this.util.toasterShowSuccess("Sucesso", "OP Ecerrada com sucesso!");
        this.carreagarApontamentos();

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });*/

  }

}
