import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-ordem-producao',
  templateUrl: './ordem-producao.component.html',
  styleUrls: ['./ordem-producao.component.scss']
})
export class OrdemProducaoComponent implements OnInit {

  montagem_turno: any;
  nome: string;
  maquina: string;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
  ) {



  }

  ngOnInit() {
    this.montagem_turno = this.activatedRoute.snapshot.params['ordem'];
    this.nome = this.activatedRoute.snapshot.params['nome'];
    this.maquina = this.activatedRoute.snapshot.params['maquina'];
  }

  produtoAcabado() {
    this.router.navigate(['/produto-acabado', this.montagem_turno, this.nome, this.maquina]);
  }

  parado() {
    this.router.navigate(['/parado', this.montagem_turno, this.nome, this.maquina]);
  }

  perda() {
    this.router.navigate(['/perda', this.montagem_turno, this.nome, this.maquina]);
  }

  pesoMedio() {
    this.router.navigate(['/peso-medio', this.montagem_turno, this.nome, this.maquina]);
  }

  voltar() {
    this.router.navigate(['/manutencao-apontamentos']);
  }

}
