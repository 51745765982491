import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { SharedModule } from './theme/shared/shared.module';

import { AppComponent } from './app.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import { AuthComponent } from './theme/layout/auth/auth.component';
import { NavigationComponent } from './theme/layout/admin/navigation/navigation.component';
import { NavContentComponent } from './theme/layout/admin/navigation/nav-content/nav-content.component';
import { NavGroupComponent } from './theme/layout/admin/navigation/nav-content/nav-group/nav-group.component';
import { NavCollapseComponent } from './theme/layout/admin/navigation/nav-content/nav-collapse/nav-collapse.component';
import { NavItemComponent } from './theme/layout/admin/navigation/nav-content/nav-item/nav-item.component';
import { NavBarComponent } from './theme/layout/admin/nav-bar/nav-bar.component';
import { NavLeftComponent } from './theme/layout/admin/nav-bar/nav-left/nav-left.component';
import { NavSearchComponent } from './theme/layout/admin/nav-bar/nav-left/nav-search/nav-search.component';
import { NavRightComponent } from './theme/layout/admin/nav-bar/nav-right/nav-right.component';
import {ChatUserListComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/chat-user-list.component';
import {FriendComponent} from './theme/layout/admin/nav-bar/nav-right/chat-user-list/friend/friend.component';
import {ChatMsgComponent} from './theme/layout/admin/nav-bar/nav-right/chat-msg/chat-msg.component';
import { ConfigurationComponent } from './theme/layout/admin/configuration/configuration.component';

import { ToggleFullScreenDirective } from './theme/shared/full-screen/toggle-full-screen';
import { ToastrModule } from 'ngx-toastr';
import { NgxSpinnerModule } from "ngx-spinner";


/* Menu Items */
import { NavigationItem } from './theme/layout/admin/navigation/navigation';
import { NgbButtonsModule, NgbDatepickerModule, NgbDropdownModule, NgbProgressbarModule, NgbTabsetModule, NgbTooltipModule } from '@ng-bootstrap/ng-bootstrap';
import { HomeComponent } from './pages/home/home.component';
import { ManutencaoEncerramentoComponent } from './pages/manutencao-encerramento/manutencao-encerramento.component';
import { ManutencaoApontamentosComponent } from './pages/manutencao-apontamentos/manutencao-apontamentos.component';
import { OrdemProducaoComponent } from './pages/ordem-producao/ordem-producao.component';
import { ProdutoAcabadoComponent } from './pages/ordem-producao/produto-acabado/produto-acabado.component';
import { ParadoComponent } from './pages/ordem-producao/parado/parado.component';
import { PerdaComponent } from './pages/ordem-producao/perda/perda.component';
import { PesoMedioComponent } from './pages/ordem-producao/peso-medio/peso-medio.component';
import { InclusaoApontamentoComponent } from './pages/inclusao-apontamento/inclusao-apontamento.component';
import { InicioApontamentoNoTurnoComponent } from './pages/inicio-apontamento-no-turno/inicio-apontamento-no-turno.component';
import { HttpClientModule } from '@angular/common/http';
import { SelectModule } from 'ng-select';
import { CommonModule } from '@angular/common';
import { FullTextSearchPipe  } from './shared/filter.pipe';
import { LoggedInGuard } from './pages/security/loggedin.quard';
import { LoginComponent } from './pages/login/login.component';
import { OpComponent } from './pages/relatorio/op/op.component';
import { RealizadoComponent } from './pages/relatorio/realizado/realizado.component';
import { ProgramadoComponent } from './pages/relatorio/programado/programado.component';
import { GeralComponent } from './pages/relatorio/geral/geral.component';




@NgModule({
  declarations: [
    AppComponent,
    AdminComponent,
    AuthComponent,
    NavigationComponent,
    NavContentComponent,
    NavGroupComponent,
    NavCollapseComponent,
    NavItemComponent,
    NavBarComponent,
    NavLeftComponent,
    NavSearchComponent,
    NavRightComponent,
    ChatUserListComponent,
    FriendComponent,
    ChatMsgComponent,
    ConfigurationComponent,
    ToggleFullScreenDirective,
    HomeComponent,
    ManutencaoEncerramentoComponent,
    ManutencaoApontamentosComponent,
    OrdemProducaoComponent,
    ProdutoAcabadoComponent,
    ParadoComponent,
    PerdaComponent,
    PesoMedioComponent,
    InclusaoApontamentoComponent,
    InicioApontamentoNoTurnoComponent,
    FullTextSearchPipe,
    LoginComponent,
    OpComponent,
    RealizadoComponent,
    ProgramadoComponent,
    GeralComponent,
    

  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    SharedModule,
    NgxSpinnerModule,
    HttpClientModule,
    NgbDropdownModule,
    NgbTooltipModule,
    NgbButtonsModule,
    NgbTabsetModule,
    NgbProgressbarModule,
    ToastrModule.forRoot(), // ToastrModule added
    CommonModule,
    SelectModule,
    NgbDatepickerModule
  ],
  providers: [NavigationItem,LoggedInGuard],
  bootstrap: [AppComponent]
})
export class AppModule { }
