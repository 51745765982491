import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-peso-medio',
  templateUrl: './peso-medio.component.html',
  styleUrls: ['./peso-medio.component.scss']
})
export class PesoMedioComponent implements OnInit {

  public apontamentos: any;
  public apontamentoSelecionado: any;
  id_montagem_turno: any;
  public nome: string;
  public maquina: string;

  public operadores: any;
  public operadorSelecionado: any;

  public nome_operador: string;
  public peso1: number;
  public peso2: number;
  public peso3: number;
  public hora: string;
  public observacao: string;
  public ehEditar: boolean;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private login: LoginService
  ) { }

  ngOnInit() {
    this.id_montagem_turno = parseInt(this.activatedRoute.snapshot.params['ordem']);
    this.nome = this.activatedRoute.snapshot.params['nome'];
    this.maquina = this.activatedRoute.snapshot.params['maquina'];

    this.limpar();
    this.carreagarApontamentos();
    this.buscarOperador();
    this.ehEditar = false;
  }

  salvarEditar() {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja Realmente Salvar essa Alteração?',
      type: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
    }).then((willDelete) => {

      if (willDelete.dismiss) {
        //Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {


        this.login.getUser();
        if (this.validar()) {
          let url = API + 'ApontamentoPesoMedio/ApontamentoPesoMedio/EditarPesoMedio';
          let dados = {
            "id_montagem_turno": this.id_montagem_turno,
            "peso1": this.peso1,
            "peso2": this.peso2,
            "peso3": this.peso3,
            "operador": this.operadorSelecionado.toString(),
            "observacao": this.observacao,
            "USUARIO_AD": this.login.user.login,
            "iD_APONTAMENTO_PESO_MEDIO": this.apontamentoSelecionado.iD_APONTAMENTO_PESO_MEDIO
          };

          this.util.showLoading();
          this.ajax.post(url, dados)
            .subscribe((op: ResponseNet) => {


              this.util.closeLoading();
              this.util.toasterShowSuccess("Sucesso", "Edicão feita com sucesso!");
              this.carreagarApontamentos();
              this.limpar();


            }, err => {
              debugger
              this.util.closeLoading();
              this.util.toasterShowError("Atenção", err.error);
            });
        }



      }
    });
  }


  editar(apt) {
    this.ehEditar = true;
    console.log(apt);
    this.apontamentoSelecionado = apt;
    this.hora = apt.hora.split('T')[1];
    this.peso1 = parseFloat((apt.pesO1).toFixed(4));
    this.peso2 = parseFloat((apt.pesO2).toFixed(4));
    this.peso3 = parseFloat((apt.pesO3).toFixed(4));
    this.observacao = apt.observacao;
    this.operadorSelecionado = apt.iD_OPERADOR
  }

  excluir(apt) {
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja Realmente Excluir esse Apontamento?',
      type: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
    }).then((willDelete) => {

      if (willDelete.dismiss) {
        //Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {



        let url = API + 'ApontamentoPesoMedio/ApontamentoPesoMedio/ExcluirPesoMedio';
        debugger
        let dados = {
          "iD_APONTAMENTO_PESO_MEDIO": apt.iD_APONTAMENTO_PESO_MEDIO
        };

        this.util.showLoading();
        this.ajax.post(url, dados)
          .subscribe((op: ResponseNet) => {

            this.limpar();
            this.util.closeLoading();
            this.util.toasterShowSuccess("Sucesso", "Lancamento exluido com sucesso!");
            this.carreagarApontamentos();

          }, err => {
            debugger
            this.util.closeLoading();
            this.util.toasterShowError("Atenção", err.error);
          });

      }
    });
  }

  buscarOperador() {
    this.login.getUser();
    this.util.showLoading();
    var url = API + 'Operador/Operador?codigo=' + this.login.user.turnoCodigo;;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.operadores = apt.data;
        console.log(this.operadores);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  carreagarApontamentos() {
    this.util.showLoading();
    var url = API + 'ApontamentoPesoMedio/ApontamentoPesoMedio?ID_MONTAGEM_TURNO=' + this.id_montagem_turno;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.apontamentos = apt.data;

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  validar(): boolean {

    if (this.peso1 <= 0) {
      this.util.toasterShowError("Erro", "O Campo Peso1 não pode ser 0");
      return false;
    }

    if (this.peso2 <= 0) {
      this.util.toasterShowError("Erro", "O Campo Peso2 não pode ser 0");
      return false;
    }

    if (this.peso3 <= 0) {
      this.util.toasterShowError("Erro", "O Campo Pes3 não pode ser 0");
      return false;
    }

    if (this.operadorSelecionado == undefined) {
      this.util.toasterShowError("Erro", "O Campo Operador não pode ser vazio");
      return false;
    }

    return true;
  }



  salvar() {
    this.login.getUser();
    if (this.validar()) {
      let url = API + 'ApontamentoPesoMedio/ApontamentoPesoMedio/SalvarAscync';
      let dados = {
        "id_montagem_turno": this.id_montagem_turno,
        "peso1": this.peso1,
        "peso2": this.peso2,
        "peso3": this.peso3,
        "operador": this.operadorSelecionado,
        "observacao": this.observacao,
        "USUARIO_AD": this.login.user.login
      };

      this.util.showLoading();
      this.ajax.post(url, dados)
        .subscribe((op: ResponseNet) => {

          this.limpar();
          this.util.closeLoading();
          this.util.toasterShowSuccess("Sucesso", "Lancamento de Peso Médio feito com sucesso!");
          this.carreagarApontamentos();

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", err.message);
        });
    }
    //console.log(this.observacao);
  }

  limpar() {
    this.peso1 = 0;
    this.peso2 = 0;
    this.peso3 = 0;
    this.nome_operador = "";
    this.hora = new Date().getHours().toString() + " : " + new Date().getMinutes().toString();
    this.observacao = "";
    this.ehEditar = false;
  }
  voltar() {
    this.router.navigate(['/ordem-producao', this.id_montagem_turno, this.nome, this.maquina]);
  }

}
