import { User } from './../../model/user.model';
import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  public maquinaOciosas: any;
  public maquinaParadas: any;
  public maquinaFuncionando: any;
  public pesoMedioProdutos: any;
  public previstoXRealizados: any;

  public user: User;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private login: LoginService

  ) { }

  ngOnInit() {
    this.login.getUser();
    this.user = this.login.user;
    this.buscarMaquinasOciosas();
    this.buscarMaquinasParada();
    this.buscarMaquinasFuncionando();
    this.buscarPesoMedioProduto();
    this.buscarPrevistoXRealizado();
  }

  buscarPrevistoXRealizado(){
    
    var url = API + 'Home/Home/buscarPrevistoXrealizado?turno='+this.user.turnoCodigo;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        debugger
        this.previstoXRealizados = apt.data;  

      }, err => { 
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  buscarPesoMedioProduto(){
    
    var url = API + 'Home/Home/buscarPesoMedioPorProduto?turno='+this.user.turnoCodigo;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        debugger
        this.pesoMedioProdutos = apt.data;  

      }, err => { 
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  buscarMaquinasFuncionando(){
    
    var url = API + 'MaquinaFuncionando/?turno='+this.user.turnoCodigo;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.maquinaFuncionando = apt.data;  

      }, err => { 
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  buscarMaquinasParada(){
    
    var url = API + 'Home/Home/buscarMaquinasParadas?turno='+this.user.turnoCodigo;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.maquinaParadas = apt.data;  

      }, err => { 
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  buscarMaquinasOciosas(){
    
    var url = API + 'Home/Home/buscarMaquinasOciosa?turno='+this.user.turnoCodigo;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.maquinaOciosas = apt.data;  

      }, err => { 
        this.util.toasterShowError("Atenção", err.message);
      });
  }

}
