

 <ngx-spinner
 bdColor="rgba(51,51,51,0.8)"
 size="medium"
 color="#fff"
 type="ball-scale-multiple"
>
 <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

  <div class="row">
    <div class="col-md-12">
      <app-card cardTitle="Iniciar Ordens no Turno" blockClass="p-0" [options]="false">
        <div class="form-group col-4">
          <label for="exampleInputEmail1">Filtro</label>
          <input type="text" class="form-control" [(ngModel)]="query" placeholder="Entre com seu Filtro">
          
        </div>
        <div class="table-responsive">
          <table class="table table-hover m-b-0">
            <thead>
              <tr >
                <th >Programacao</th>
                <th >Quantidade</th>
                <th >O.P.</th>
                <th >Produto</th>
                <th >Máquina</th>
                <th >Turno</th>
                <th >Inicio OP</th>
                <th >Ver</th>
              </tr>
            </thead>
            <tbody>
             
              <tr  *ngFor="let op of ordens  | fullTextSearch:query">
                <td><small>{{op.programacao_numero}}</small></td>
                <td ><small>{{op.quantidade}}</small></td>
                <td >
                  <h6 *ngIf="op.op_numero != null" class="m-0"><small> {{op.op_numero}}</small> </h6>
                  <h6 *ngIf="op.op_numero == null" class="m-0"><small>{{op.numerO_OP_AVULSA}} </small></h6>
                  
                </td>
                <td ><small> {{op.produto_desc | slice:0:50}} </small></td>
                <td > <small>{{op.maquina_desc}}</small></td>
                <td > <small>{{op.turno_descricao}}</small></td>
                <td > <small> <input type="datetime-local" [(ngModel)]="op.inicio_producao" class="form-control" > </small></td>
                 <td >
                  <button type="button" class="btn btn-sm btn-icon btn-success" (click)="iniciar(op)"><i class="feather icon-check" ></i> Iniciar</button>
                  <button  type="button" class="btn btn-sm btn-icon btn-danger" (click)="reprovar(op)"><i class="feather icon-x-circle"></i></button>
                </td>
              </tr>

            </tbody>
          </table>
        </div>
      </app-card>
    </div>

  </div>
  
  