<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>
<div class="row">
  <!-- order-card start -->
  <div class="col-12">
    <div class="page-header ng-star-inserted">
      <div class="page-block">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="page-header-title">
              <h5 class="card text-white bg-danger text-center">
                Lancamento de Perda
                <br>{{nome}}<br> Maquina: {{maquina}}
              </h5>
            </div>
            <div class="">
              <form action="javascript:">
                <div class="form-row">

                  <div class="form-group col-md-3">
                    <label>Tipo</label>
                    <select class="form-control select2" style="width: 100%;" [(ngModel)]="tipo" name="tipo"
                      (change)="selecionarTipo()">
                      <option value="Peca">Peça</option>
                      <option value="Borra">Borra</option>
                      <option value="Canal">Canal</option>
                      <option value="Varredura">Varredura</option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputEmail4">Quantidade</label>
                    <input type="number" class="form-control" [(ngModel)]="quantidade" name="quantidade"
                      placeholder="Quantidade">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputPassword4">Peso</label>
                    <input type="number" class="form-control" [(ngModel)]="peso" name="peso" placeholder="Peso">
                  </div>

                </div>

                <div class="form-row">
                  <div class="form-group col-md-4">
                    <label>Grupo</label>
                    <select (change)="buscaGrupoIdentificador(grupoSelecionado)" class="form-control select2"
                      style="width: 100%;" [(ngModel)]="grupoSelecionado" name="grupoSelecionado">
                      <option *ngFor="let id of grupos" [value]="id.value">{{id.label}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">
                    <label>Identificador</label>
                    <select class="form-control select2" style="width: 100%;" [(ngModel)]="identicicadorSelecionado"
                      name="identicicadorSelecionado">
                      <option [value]="null">Selecione um Item</option>
                      <option *ngFor="let id of identificadores" [value]="id.value">{{id.label}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputAddress">Hora</label>
                    <input disabled type="datetime" class="form-control" [(ngModel)]="hora" name="hora"
                      placeholder="Inicio">
                  </div>
                </div>
                

                <div class="form-row">

                  
                  <div class="form-group col-md-6">
                    <label for="inputAddress2">Operador</label>
                    <select class="form-control select2" style="width: 100%;" [(ngModel)]="operadorSelecionado" name="operadorSelecionado">
                      <option [value]="null">Selecione um Item</option>
                      <option *ngFor="let id of operadores" [value]="id.id"  >{{id.rA_NOME}} - {{id.turno_descricao}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputAddress2">Observação</label>
                    <textarea [(ngModel)]="observacao" name="observacao" type="text" row="5" class="form-control"
                      placeholder="Observação">
                        </textarea>
                  </div>
                </div>
                <button  *ngIf="!ehEditar" type="button" class="btn btn-success fa-pull-right" (click)="salvar()">
                  <i class="feather icon-save"></i>
                  Salvar</button>
                  <button *ngIf="ehEditar" type="button" class="btn btn-info fa-pull-right" (click)="salvarEditar()">
                    <i class="feather icon-save"></i>
                    Atualizar</button>
                <button type="button" class="btn btn-warning fa-pull-left" (click)="voltar()">
                  <i class="feather icon-skip-back"></i>
                  Voltar
                </button>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <app-card cardTitle="Lancamentos Atuais" cardClass="table-card" blockClass="p-0" [options]="false">
      <perfect-scrollbar [style.max-height]="'415px'">
        <div class="table-responsive">
          <table class="table table-hover m-b-0">
            <thead>
              <tr>
                <th>Hora</th>
                <th>Tipo</th>
                <th>Quant.</th>
                <th>Peso</th>
                <th>Identif.</th>
                <th>Operador</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let ap of apontamentos">
                <td>{{ap.hora | date: 'HH:mm'}}</td>
                <td>{{ap.tipo}}</td>
                <td>
                  {{ap.quantidade}}
                </td>
                <td>
                  <div>
                    {{ap.peso  | number:'.4-4'}}
                  </div>
                </td>
                <td>{{ap.nome_identificador}}</td>
                <td>{{ap.operador}}</td>
                <td>
                  <a href="javascript:" (click)="editar(ap)">
                    <i class="icon feather icon-edit f-28  text-c-green"></i>
                  </a>
                  <a href="javascript:" (click)="excluir(ap)">
                    <i class="feather icon-trash-2 ml-3 f-28 text-c-red"></i>
                  </a>
                  <!--<a href="javascript:">
                        <i class="icon feather icon-edit f-28  text-c-green"></i>
                      </a>
                      <a href="javascript:">
                        <i class="feather icon-trash-2 ml-3 f-28 text-c-red"></i>
                      </a>-->
                </td>
              </tr>





            </tbody>
          </table>
        </div>
      </perfect-scrollbar>
    </app-card>
  </div>

</div>
