<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>
<div class="row">
  <!-- order-card start -->
  <div class="col-12">
    <div class="page-header ng-star-inserted">
      <div class="page-block">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="page-header-title">
              <h5 class="card text-white bg-warning text-center">
                Lancamento de Peso Médio
              <br>{{nome}}<br> Maquina: {{maquina}}
              </h5>
            </div>
            <div class="">
              <form action="javascript:">
                <div class="form-row">


                  <div class="form-group col-md-4 ">
                    <label for="inputEmail4">Peso 1</label>
                    <input type="number" class="form-control" [(ngModel)]="peso1" name="peso1" placeholder="Peso 1">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputPassword4">Peso 2</label>
                    <input type="number" class="form-control" [(ngModel)]="peso2" name="peso2" placeholder="Peso 2">
                  </div>
                  <div class="form-group col-md-4">
                    <label for="inputPassword4">Peso 3</label>
                    <input type="number" class="form-control" [(ngModel)]="peso3" name="peso3" placeholder="Peso 3">
                  </div>

                </div>

                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label for="inputAddress">Hora</label>
                    <input disabled type="datetime" class="form-control" [(ngModel)]="hora" name="hora" placeholder="Inicio">
                  </div>
                  <div class="form-group col-md-9">
                    <label for="inputAddress2">Operador</label>
                    <select class="form-control select2" style="width: 100%;" [(ngModel)]="operadorSelecionado" name="operadorSelecionado">
                      <option [value]="null">Selecione um Item</option>
                      <option *ngFor="let id of operadores" [value]="id.id"  >{{id.rA_NOME}} - {{id.turno_descricao}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputAddress2">Observação</label>
                    <textarea  [(ngModel)]="observacao" name="observacao" type="text" row="5" class="form-control"  placeholder="Observação">
                      </textarea>
                  </div>
                </div>
                <button *ngIf="!ehEditar" type="button" class="btn btn-success fa-pull-right" (click)="salvar()">
                  <i class="feather icon-save"></i>
                  Salvar</button>
                  <button *ngIf="ehEditar" type="button" class="btn btn-info fa-pull-right" (click)="salvarEditar()">
                    <i class="feather icon-save"></i>
                    Atualizar</button>
                <button type="button" class="btn btn-warning fa-pull-left" (click)="voltar()">
                  <i class="feather icon-skip-back"></i>
                  Voltar
                </button>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <app-card cardTitle="Lancamentos Atuais" cardClass="table-card" blockClass="p-0" [options]="false">
      <perfect-scrollbar [style.max-height]="'415px'">
        <div class="table-responsive">
          <table class="table table-hover m-b-0">
            <thead>
              <tr>
                <th>Hora</th>
                <th>Peso 1</th>
                <th>Peso 2</th>
                <th>Peso 3</th>
                <th>Operador</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>

              <tr *ngFor="let ap of apontamentos" >
                <td>{{ap.hora  | date: 'HH:mm'}}</td>
                <td>{{ap.pesO1  | number:'.4-4'}}</td>
                <td>{{ap.pesO2  | number:'.4-4'}}</td>
                <td>{{ap.pesO3  | number:'.4-4'}}</td>
                <td>{{ap.nomE_OPERADOR}}</td>
                <td>
                  <a href="javascript:" (click)="editar(ap)">
                    <i class="icon feather icon-edit f-28  text-c-green"></i>
                  </a>
                  <a href="javascript:" (click)="excluir(ap)">
                    <i class="feather icon-trash-2 ml-3 f-28 text-c-red"></i>
                  </a>
                  <!--<a href="javascript:">
                    <i class="icon feather icon-edit f-28  text-c-green"></i>
                  </a>-->
                </td>
              </tr>






            </tbody>
          </table>
        </div>
      </perfect-scrollbar>
    </app-card>
  </div>

</div>
