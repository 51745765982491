<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>
<div class="row">
  <!-- order-card start -->
  <div class="col-12">
    <div class="page-header ng-star-inserted">
      <div class="page-block">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="page-header-title">
              <h5 class="card text-white bg-success text-center">
                Lancamento Máquina Parada
              <br>{{nome}}<br> Maquina: {{maquina}}
              </h5>
            </div>
            <div class="">
              <form action="javascript:" *ngIf="vaiEditar">
          
                <div class="form-row">
                  <div class="form-group col-md-3">
                    <label for="inputAddress">Inicio</label>
                    <input type="datetime-local" disabled class="form-control" [(ngModel)]="inicio" name="inicio"  placeholder="Inicio">
                  </div>
                  <div class="form-group col-md-3">
                    <label for="inputEmail4">fim</label>
                    <input type="datetime-local" class="form-control" [(ngModel)]="fim" name="fim" placeholder="Fim">
                  </div>

                  <div class="form-group col-md-6">                    
                    <label>Identificador</label>
                    <input type="text" disabled class="form-control" [(ngModel)]="editarIdentificador" name="editarIdentificador"  placeholder="Inicio">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputAddress2">Operador</label>
                    <input type="text" disabled class="form-control" [(ngModel)]="nome_operador" name="nome_operador"  placeholder="Inicio">
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputAddress2">Observação</label>
                    <textarea disabled [(ngModel)]="observacao" name="observacao" type="text" row="5" class="form-control"
                      placeholder="Observação">
                      </textarea>
                  </div>
                </div>
                <button type="button" class="btn btn-info fa-pull-right" (click)="atualizar()">
                  <i class="feather icon-save"></i>
                  Atualizar</button>
                <button type="button" class="btn btn-warning fa-pull-left" (click)="voltar()">
                  <i class="feather icon-skip-back"></i>
                  Voltar
                </button>
              </form>
              <form action="javascript:" *ngIf="!vaiEditar">
                <div class="form-row">
                  <div class="form-group col-md-2">
                    <label for="inputAddress">Inicio</label>
                    <input type="datetime-local" class="form-control" [(ngModel)]="inicio" name="inicio"  placeholder="Inicio">
                  </div>
                  <div class="form-group col-md-2">
                    <label for="inputEmail4">fim</label>
                    <input type="datetime-local" class="form-control" [(ngModel)]="fim" name="fim" placeholder="Fim">
                  </div>

                  <div class="form-group col-md-4">                    
                    <label>Grupo</label>
                    <select (change)="buscaGrupoIdentificador(grupoSelecionado)" class="form-control select2" style="width: 100%;" [(ngModel)]="grupoSelecionado" name="grupoSelecionado">
                      <option [value]="null">Selecione um Item</option>
                      <option *ngFor="let id of grupos" [value]="id.value"  >{{id.label}}</option>
                    </select>
                  </div>
                  <div class="form-group col-md-4">                    
                    <label>Identificador</label>
                    <select (change)="selectOption(identicicadorSelecionado)" class="form-control select2" style="width: 100%;" [(ngModel)]="identicicadorSelecionado" name="identicicadorSelecionado"  >
                      <option [value]="null">Selecione um Item</option>
                      <option *ngFor="let id of identificadores" [value]="id.value"  >{{id.label}}</option>
                    </select>
                  </div>
                </div>

                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputAddress2">Operador</label>
                    <select class="form-control select2" style="width: 100%;" [(ngModel)]="operadorSelecionado" name="operadorSelecionado">
                      <option [value]="null">Selecione um Item</option>
                      <option *ngFor="let id of operadores" [value]="id.id"  >{{id.rA_NOME}} - {{id.turno_descricao}}</option>
                    </select>
                  </div>
                </div>
                <div class="form-row">
                  <div class="form-group col-md-12">
                    <label for="inputAddress2">Observação</label>
                    <textarea [(ngModel)]="observacao" name="observacao" type="text" row="5" class="form-control"
                      placeholder="Observação">
                      </textarea>
                  </div>
                </div>
                <button type="button" class="btn btn-success fa-pull-right" (click)="salvar()">
                  <i class="feather icon-save"></i>
                  Salvar</button>
                <button type="button" class="btn btn-warning fa-pull-left" (click)="voltar()">
                  <i class="feather icon-skip-back"></i>
                  Voltar
                </button>
              </form>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-12">
    <app-card cardTitle="Lancamentos Atuais" cardClass="table-card" blockClass="p-0" [options]="false">
      <perfect-scrollbar [style.max-height]="'415px'">
        <div class="table-responsive">
          <table class="table table-hover m-b-0">
            <thead>
              <tr>
                <th>Inicio</th>
                <th>Termino</th>
                <th>Tempo</th>
                <th>%</th>
                <th>Identif.</th>
                <th>Operador</th>
                <th>Ação</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ap of apontamentos" >
                <!--<td>{{ap.inicio | date: 'dd/MM/yyyy HH:mm'}}</td>-->
                <td>{{ap.inicio | date: 'HH:mm'}}</td>
                <td>{{ap.fim | date: 'HH:mm'}}</td>
                <td>
                  {{ap.tempo}}
                </td>
                <td>
                  <div>
                    <label class="badge badge-light-danger"> {{ap.porcentagem}} </label>
                  </div>
                </td>
                <td>{{ap.identificador}}</td>
                <td>{{ap.operador}}</td>
                <td>
                  <a href="javascript:" (click)="editar(ap)" *ngIf="ap.fim == '0001-01-01T00:00:00'" >
                    <i class="icon feather icon-edit f-28  text-c-green"></i>
                  </a>
                  <a href="javascript:" (click)="excluir(ap)">
                    <i class="feather icon-trash-2 ml-3 f-28 text-c-red"></i>
                  </a>
                  <!--<a href="javascript:">
                    <i class="icon feather icon-edit f-28  text-c-green"></i>
                  </a>
                  <a href="javascript:">
                    <i class="feather icon-trash-2 ml-3 f-28 text-c-red"></i>
                  </a>-->
                </td>
              </tr>





            </tbody>
          </table>
        </div>
      </perfect-scrollbar>
    </app-card>
  </div>

</div>
