<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>
<div class="row">
  <div class="col-lg-6">
    <app-card cardTitle="Inclusão de um Novo Apontamento" [options]="false">

      <form action="javascript:">
        <div class="form-row">
          <div class="form-group col-md-12">
            <label>Produto</label>
            <ng-select [options]="produtos" [ngModelOptions]="{standalone: true}"  [(ngModel)]="produtoSelecionado" >
            </ng-select>
            <!--<select class="form-control select2" style="width: 100%;">
              <option *ngFor="let prod of produtos" value="{{prod.id}}">{{prod.descricao}}</option>
            </select>-->
          </div>

        </div>

        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputAddress">Ordem Produção</label>
            <input type="datetime" class="form-control"   [(ngModel)]="ordem_producao" name="ordem_producao" placeholder="Ordem Produção">
          </div>
          <!--<div class="col-sm-12 col-xl-4">
            <h5 class="sub-title">Single Select</h5><hr/>
            <ng-select 
             [ngClass]="'ng-select'"
             [options]="maquinas" 
             [(ngModel)]="maquinaSelecionada">
             </ng-select>
          
          </div>-->


          <div class="form-group col-md-6">

            <label>Maquina</label>
            <ng-select [options]="maquinas" [ngModelOptions]="{standalone: true}" [(ngModel)]="maquinaSelecionada">
            </ng-select>
          </div>

        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
           
            <label for="inputAddress">Inicio Produção</label>
            <input type="datetime-local" placeholder="Data Inicial" [(ngModel)]="inicio_producao" name="inicio_producao" class="form-control ng-pristine ng-valid">
            <!--<div class="input-group">
              <input  type="datetime-local"  class="form-control" autocomplete="off" placeholder="yyyy-mm-dd" name="inicio_producao" [(ngModel)]="inicio_producao" ngbDatepicker #d="ngbDatepicker">
              <div class="input-group-append">
                <span class="input-group-text" (click)="d.toggle()">
                  <i class="fa fa-calendar"></i>
                </span>
              </div>
            </div>-->
            <!--<input type="datetime" class="form-control"  name="inicio_producao" placeholder="Inicio Produção">-->
          </div>
          <div class="form-group col-md-6">
            <label for="inputPassword4">Fim Produção</label>
            <input type="datetime-local" placeholder="Data Inicial" [(ngModel)]="fim_producao" name="fim_producao" class="form-control ng-pristine ng-valid">
            <!--<div class="input-group">
              <input class="form-control" autocomplete="off" placeholder="yyyy-mm-dd" name="fim_producao" [(ngModel)]="fim_producao" ngbDatepicker #df="ngbDatepicker">
              <div class="input-group-append">
                <span class="input-group-text" (click)="df.toggle()">
                  <i class="fa fa-calendar"></i>
                </span>
              </div>
            </div>-->
            

            <!--<input type="datetime" class="form-control" name="fim_producao" placeholder="Fim Producao">-->
          </div>
        </div>
        <div class="form-row">
          <div class="form-group col-md-6">
            <label for="inputAddress">Emissão</label>
            <input type="datetime" disabled class="form-control" [(ngModel)]="data" name="data" placeholder="Emissão">
          </div>
          <!--<div class="form-group col-md-4">
            <label for="inputEmail4">Requisição</label>
            <input type="number" class="form-control" id="inputEmail4" placeholder="Requisição">
          </div>-->
          <div class="form-group col-md-6">
            <label for="inputPassword4">Quantidade</label>
            <input type="number" class="form-control" [(ngModel)]="quantidade" name="quantidade" placeholder="Saldo">
          </div>
        </div>
        <div class="form-row">
        <div class="form-group col-md-6">
            <label for="inputPassword4">Numero Programação</label>
            <input type="text" class="form-control" [(ngModel)]="numero_programacao" name="numero_programacao" placeholder="Número Programação">
          </div>
          <div class="form-group col-md-6">
            <label for="inputPassword4">% Excedente Permitida</label>
            <input type="number" class="form-control" [(ngModel)]="porc" name="porc" placeholder="Saldo">
          </div>
        </div>
        <div class="form-row" *ngIf="this.user.turnoCodigo == '0'">
          <div class="form-group col-md-12">
            <label>Turno</label>
            <!--<ng-select [options]="produtos" [ngModelOptions]="{standalone: true}"  [(ngModel)]="produtoSelecionado" >
            </ng-select>-->
            <select class="form-control select2" style="width: 100%;" [(ngModel)]="turnoSelecionado" name="turnoSelecionado">
              <option *ngFor="let turno of turnos" [value]="turno.r6_TURNO"  >{{turno.r6_DESC}}</option>
            </select>
          </div>

        </div>
        <div class="form-row">
          <div class="form-group col-md-12">
            <label for="inputAddress">Motivo</label>
            <textarea [(ngModel)]="motivo" name="motivo" rows="3" class="form-control"
              placeholder="Digite o Motivo para Inclusao"></textarea>
          </div>

        </div>
        


        <button type="button" class="btn btn-success fa-pull-right" (click)="salvar()">
          <i class="feather icon-save"></i>
          Salvar</button>
        <button type="button" class="btn btn-warning fa-pull-left" (click)="voltar()">
          <i class="feather icon-skip-back"></i>
          Voltar
        </button>
      </form>

    </app-card>
  </div>

</div>
