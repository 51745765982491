import { User } from './../../model/user.model';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { IOption } from 'ng-select';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';

@Component({
  selector: 'app-inclusao-apontamento',
  templateUrl: './inclusao-apontamento.component.html',
  styleUrls: ['./inclusao-apontamento.component.scss']
})
export class InclusaoApontamentoComponent implements OnInit {

  public produtos: Array<IOption>;
  public turnos: any;
  public maquinas: Array<IOption>;

  public maquinaSelecionada: any;
  public produtoSelecionado: any;
  public turnoSelecionado: any;

  public ordem_producao: string;
  public numero_programacao: string;
  public data: string;
  public inicio_producao: string;
  public fim_producao:string;
  public motivo: string;
  public quantidade: number;
  public porc: number;
  public user: User;

  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private login: LoginService
  ) { 

    this.user = this.login.getUser();

  }

  ngOnInit() {
    this.carregarProduto();
    this.carregarMaquinas();
    this.carregarTurnos();
    this.limpar();
  }

  limpar() {
    this.quantidade = 0;
    this.ordem_producao = "";
    this.motivo = "";
    this.data = new Date().toLocaleDateString()
    this.porc = 0;
    this.inicio_producao = "";
    this.fim_producao = "";
    /*this.inicio_producao = {day:"", month:"", year:""};
    this.fim_producao = {day:"", month:"", year:""};*/
  }


  changePn(val){
    console.log("Dropdown selection:", val);
  }
  validar(): boolean {


    if (this.produtoSelecionado == undefined) {
      this.util.toasterShowError("Erro", "Selecione um Produto");
      return false;
    }

    if (this.ordem_producao == "") {
      this.util.toasterShowError("Erro", "O Campo Ordem Producao não pode ser vazio");
      return false;
    }

    if (this.maquinaSelecionada == undefined) {
      this.util.toasterShowError("Erro", "Selecione uma Maquina");
      return false;
    }

    if (this.numero_programacao == "") {
      this.util.toasterShowError("Erro", "O Campo Número Programaçao!");
      return false;
    }

    if (this.quantidade <= 0) {
      this.util.toasterShowError("Erro", "O Campo Quantidade não pode ser 0");
      return false;
    }

    if (this.inicio_producao == "") {
      this.util.toasterShowError("Erro", "O Campo Inicio Produção não pode ser vazio");
      return false;
    }

    if(this.user.turnoCodigo == "0"){
      if (this.turnoSelecionado == undefined) {
        this.util.toasterShowError("Erro", "Selecione um Turno");
        return false;
      }
    }

    return true;
  }
  
  salvar() {

    this.login.getUser();
    debugger
    if (this.validar()) {
      let url = API + 'Op/Op/CriarOPAvulsa';
      console.log(typeof(this.inicio_producao))
      let dados = {
        "C2_QUANT": this.quantidade,
        "NUMERO_OP_AVULSA": this.ordem_producao,
        "MOTIVO_AVULSA": this.motivo,
        "ID_PRODUTO": parseInt(this.produtoSelecionado),
        "ID_MAQUINA": parseInt(this.maquinaSelecionada),
        "PORC_EXCESSO_PERMITIDA": this.porc,
        "CODIGO_PROGRAMACAO": this.numero_programacao,
        "INICIO_PRODUCAO": this.inicio_producao,
        "FIM_PRODUCAO": this.fim_producao != "" ? this.fim_producao : null,
        "R6_TURNO": this.user.turnoCodigo != "0" ?  this.login.user.turnoCodigo : this.turnoSelecionado
        
      };

      /*let dados = {
        "C2_QUANT": this.quantidade,
        "NUMERO_OP_AVULSA": this.ordem_producao,
        "MOTIVO_AVULSA": this.motivo,
        "ID_PRODUTO": parseInt(this.produtoSelecionado),
        "ID_MAQUINA": parseInt(this.maquinaSelecionada),
        "PORC_EXCESSO_PERMITIDA": this.porc,
        "CODIGO_PROGRAMACAO": this.numero_programacao,
        "R6_TURNO": this.user.turnoCodigo != "0" ?  this.login.user.turnoCodigo : this.turnoSelecionado
        
      };*/

      this.util.showLoading();
      this.ajax.post(url, dados)
        .subscribe((op: ResponseNet) => {

          this.limpar();
          this.util.closeLoading();
          this.util.toasterShowSuccess("Sucesso", "Lancamento de Produdo feito com sucesso!");

          debugger

          let url = API + 'Op/Op/CriarApontamento';
          let dados = {
            "op_id": op.data.iD_OP,
            "status": 2,
            "maquina_id": parseInt(this.maquinaSelecionada),
            "USUARIO_AD": this.login.user.login,
            "turno_codigo": this.user.turnoCodigo != "0" ?  this.login.user.turnoCodigo : this.turnoSelecionado
          };
      
          
          this.util.showLoading();
          this.ajax.post(url, dados)
            .subscribe((opm: ResponseNet) => {
      
              this.util.closeLoading();
              this.util.toasterShowSuccess("Sucesso", "OP " + opm.data.numerO_OP_AVULSA + " incluida com sucesso!");
      
            }, err => {
              debugger
              this.util.closeLoading();
              this.util.toasterShowError("Atenção", err.message);
            });


        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", err.error);
        });
    }
    //console.log(this.observacao);
  }

  carregarProduto(){
    this.util.showLoading();
    var url = API + 'Produto/Produto/BuscarProdutosDropDow';
    this.ajax.get(url)
      .subscribe((prod: ResponseNet) => {

        console.log(prod);
        this.util.closeLoading();
        this.produtos = prod.data;
        //this.formasPagamento = pagamento.data;

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.error);
      });
  }

  carregarMaquinas(){
    this.util.showLoading();
    var url = API + 'Maquina/Maquina/BuscarMaquinaDropDow';
    this.ajax.get(url)
      .subscribe((maq: ResponseNet) => {

        this.util.closeLoading();
        this.maquinas = maq.data;

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.error);
      });
  }

  carregarTurnos(){
    this.util.showLoading();
    this.login.getUser();
    var url = API + 'Turno/turno?R6_TURNO='+this.login.user.turnoCodigo;
    this.ajax.get(url)
      .subscribe((turno: ResponseNet) => {

        this.util.closeLoading();
        this.turnos = turno.data;

      }, err => {
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.error);
      });
  }

  voltar(){
    this.router.navigate(['/manutencao-apontamentos']);
  }

}
