<div class="row">
  <!-- order-card start -->
  <div class="col-12">
    <div class="page-header ng-star-inserted">
      <div class="page-block">
        <div class="row align-items-center">
          <div class="col-md-12">
            <div class="page-header-title">
              <h5 class="m-b-10 ng-star-inserted">Lancamentos : {{nome}}  <span class="fa-pull-right" >Maquina: {{maquina}}</span> </h5>
              <button type="button" class="btn btn-warning fa-pull-left" (click)="voltar()">
                <i class="feather icon-skip-back"></i>
                Voltar
              </button>

            </div>
            <ul class="breadcrumb fa-pull-right">
              Clique no Botão abaixo para fazer o respectivo lancamento
            </ul>

          </div>
        </div>
      </div>
    </div>
  </div>

  <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-blue order-card" (click)="produtoAcabado()">
      <h2 class="text-right text-white">
        <i class="feather icon-package float-left"></i><span>PRODUTO ACABADO</span></h2>
    </app-card>
  </div>
  <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-green order-card" (click)="parado()">
      <h2 class="text-right text-white"><i class="feather icon-pause float-left">
        </i><span>PARADAS <br> &nbsp; </span>
      </h2>
    </app-card>
  </div>
  <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-red order-card" (click)="perda()">
      <h2 class="text-right text-white"><i class="feather icon-x float-left">
        </i><span>PERDAS <br> &nbsp;</span></h2>
    </app-card>
  </div>
  <div class="col-md-6 col-xl-3">
    <app-card [hidHeader]="true" cardClass="bg-c-yellow order-card" (click)="pesoMedio()">
      <h2 class="text-right text-white"><i class="feather icon-award float-left"></i>
        <span>PESO MÉDIO PROD</span></h2>
    </app-card>
  </div>


</div>
