import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'fullTextSearch'
})
export class FullTextSearchPipe implements PipeTransform {

  transform(value: any[], query: string): any[] {
    return query
        ? value.filter(
              obj =>
                  Object.keys(obj)
                      .map(key => obj[key])
                      .toString()
                      .toLocaleLowerCase()
                      .indexOf(query.toLocaleLowerCase()) !== -1
          )
        : value;
}

}
