import { Injectable } from '@angular/core';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';

@Injectable({
  providedIn: 'root'
})
export class UtilService {

  options: any;

  constructor(
    private toastr: ToastrService,
    private spinner: NgxSpinnerService
  ) { 

    this.options = {
      "closeButton": true, 
      "newestOnTop": true,
      "progressBar": false,
      "positionClass": "toast-top-center",
      "preventDuplicates": false,
      "onclick": null,
      "showDuration": "300",
      "hideDuration": "1000",
      "timeOut": "5000",
      "extendedTimeOut": "1000",
      "showEasing": "swing",
      "hideEasing": "linear",
      "showMethod": "fadeIn",
      "hideMethod": "fadeOut"
  }

  }

  showLoading() {
    this.spinner.show();
  }

  closeLoading() {
    this.spinner.hide();
    /*setTimeout(() => {
      this.spinner.hide();
    }, 3000);*/
  }
 
  toasterShowSuccess(titulo: string, mensagem: string) {
    this.toastr.success(mensagem, titulo, this.options);
  }

  toasterShowError(titulo: string, mensagem: string) {
    this.toastr.error(mensagem, titulo, this.options);
  }

  toasterShowInfo(titulo: string, mensagem: string) {
    this.toastr.info(mensagem, titulo, this.options);
  }

  toasterShowWarning(titulo: string, mensagem: string) {
    this.toastr.warning(mensagem, titulo, this.options);
  }

  pad(num, size:number): string {
    let s = num+"";
    while (s.length < size) s = "0" + s;
    return s;
}

 /* toasterShowError(titulo: string, mensagem: string) {
    this.toastr.error(mensagem, titulo);
  }*/


}
