import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-manutencao-apontamentos',
  templateUrl: './manutencao-apontamentos.component.html',
  styleUrls: ['./manutencao-apontamentos.component.scss']
})
export class ManutencaoApontamentosComponent implements OnInit {


  public apontamentos: any;
  public query: string;

  constructor(
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private login: LoginService
  ) { }

  ngOnInit() {
    this.carreagarApontamentos();
  }

  excluir(ap) {


    Swal.fire({
      title: 'Atenção',
      text: 'Deseja Realmente Excluir esse Apontamento?',
      type: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
    }).then((willDelete) => {

      if (willDelete.dismiss) {
        //Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {


        let dados = {
          "id_programacao": ap.id_programacao
        };

        this.login.getUser();
        this.util.showLoading();
        var url = API + 'Programacao/Programacao/ExcluirProgramacao';
        this.ajax.post(url, dados)
          .subscribe((apt: ResponseNet) => {

            this.util.closeLoading();
            this.carreagarApontamentos();

          }, err => {

            this.util.closeLoading();
            this.util.toasterShowError("Atenção", err.message);
          });


      }
    });





  }

  carreagarApontamentos() {
    this.login.getUser();
    this.util.showLoading();
    var url = API + 'MontagemTurno/MontagemTurno/BuscarApontamentos?ID_STATUS_MONTAGEM_TURNO=2&R6_TURNO=' + this.login.user.turnoCodigo;;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.apontamentos = apt.data;

      }, err => {

        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  ordemProducao(idOP: any) {
    console.log(idOP);

    this.router.navigate(['/ordem-producao', idOP.id_montagem_turno, idOP.produto, idOP.maquina]);
  }


  inclusaoApontamento() {
    this.router.navigate(['/inclusao-apontamento']);
  }

}
