<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
  <p style="font-size: 20px; color: white">Aguarde ...</p>
</ngx-spinner>

<div class="row">
  <div class="col-12">

    <app-card cardTitle="Ordens em Andamento" cardClass="table-card" blockClass="p-0" [options]="false">
      <div class="form-group col-4">
        <label for="exampleInputEmail1">Filtro</label>
        <input type="text" class="form-control" [(ngModel)]="query" placeholder="Entre com seu Filtro">

      </div>
      <div class="table-responsive">
       
          <table class="table table-hover m-b-0">
            <thead>
              <tr>

                <th>O.P.</th>
                <th>Produto</th>
                <th>Máquina</th>
                <th>Previsto</th>
                <th>Produzido</th>
                <th>%</th>
                <th>Inicio OP</th>
                <th>Fim OP</th>
                <th>Encerrar</th>
              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let ap of apontamentos  | fullTextSearch:query">
                <td>
                  <h6 class="m-0" *ngIf="ap.op_numero > 0" >{{ap.op_numero}}  </h6>
                  <h6 class="m-0" *ngIf="ap.op_numero == 0" >{{ap.op_numero_avulso}} </h6>
                  <!--<h6 *ngIf="op.c2_NUM > 0" class="m-0">{{op.c2_NUM}} </h6>
                  <h6 *ngIf="op.c2_NUM == 0" class="m-0">{{op.numerO_OP_AVULSA}} </h6>-->
                </td>
                <td>{{ap.produto}}</td>
                <td>{{ap.maquina}}</td>
                <td>{{ap.quantidadePrevista}}</td>
                <td>{{ap.quantidadeTotal}}</td>
                <td>
 
                 {{ ap.porcentagem}} %          
                    <div class="mt-1">
                      <ngb-progressbar height="6px" [value]="ap.porcentagem"></ngb-progressbar>
                    </div>
                </td>
                <td > <small> {{ap.inicio_producao | date: 'dd/MM/yyyy'}} </small></td>
                <td > <small> <input type="datetime-local" [(ngModel)]="ap.fim_producao" class="form-control" > </small></td>
                
                <td>
                  <button type="button" class="btn btn-icon btn-danger" (click)="encerrarApontamento(ap)">
                    <i class="feather icon-x-circle"></i>
                  </button>
                </td>
              </tr>

            </tbody>
          </table>
     
      </div>
    </app-card>

  </div>
</div>
