import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-perda',
  templateUrl: './perda.component.html',
  styleUrls: ['./perda.component.scss']
})
export class PerdaComponent implements OnInit {

  id_montagem_turno: any;
  public nome: string;
  public maquina: string;

  public grupoSelecionado: any;
  public identicicadorSelecionado: any;
  public identificadores: any;
  public grupos: any;

  public apontamentos: any;

  public operadores: any;
  public operadorSelecionado: any;

  public tipo: string;
  public nome_operador: string;
  public hora: string;
  public quantidade: number;
  public peso: number;
  public observacao: string;
  public apontamentoSelecionado: any;
  public ehEditar: boolean;
  


  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private login: LoginService
  ) { }

  ngOnInit() {
    this.id_montagem_turno = parseInt(this.activatedRoute.snapshot.params['ordem']); 
    this.nome = this.activatedRoute.snapshot.params['nome'];
    this.maquina = this.activatedRoute.snapshot.params['maquina'];

    this.buscaIdentificador();
    this.limpar();
    this.carreagarApontamentos();
    this.buscarOperador();
    this.ehEditar = false;
  }

  salvarEditar(){
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja Realmente Salvar essa Alteração?',
      type: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
    }).then((willDelete) => {
        
        if (willDelete.dismiss) {
          //Swal.fire('', 'Your imaginary file is safe!', 'error');
        } else {
          

          this.login.getUser();
          if (this.validar()) {
            let url = API + 'ApontamentoPerda/ApontamentoPerda/EditarPerda';
            
            let dados = {
              "id_montagem_turno": this.id_montagem_turno,
              "quantidade": this.quantidade,
              "peso": this.peso,
              "operador": this.operadorSelecionado.toString(),
              "observacao": this.observacao,
              "tipo": this.tipo,
              "id_perda_refugo": parseInt(this.identicicadorSelecionado),
              "USUARIO_AD": this.login.user.login,
              "iD_APONTAMENTO_PERDA": this.apontamentoSelecionado.iD_APONTAMENTO_PERDA
            };
      
            this.util.showLoading();
            this.ajax.post(url, dados)
              .subscribe((op: ResponseNet) => {
      
                
                this.util.closeLoading();
                this.util.toasterShowSuccess("Sucesso", "Alteracão feita com sucesso!");
                this.carreagarApontamentos();
                this.limpar();
                
      
              }, err => {
                debugger
                this.util.closeLoading();
                this.util.toasterShowError("Atenção", err.error);
              });
          }



        }
      });
  }


  editar(apt) {
    this.ehEditar = true;
    console.log(apt);
    this.apontamentoSelecionado = apt;
    this.hora = apt.hora.split('T')[1];
    this.quantidade = apt.quantidade;
    this.peso = parseFloat((apt.peso).toFixed(4));
    this.observacao = apt.observacao;
    this.operadorSelecionado = apt.iD_OPERADOR;
    this.tipo = apt.tipo;
    this.grupoSelecionado = apt.grupo;

    this.util.showLoading();
    var url = API + 'PerdaRefugo/PerdaRefugo/BuscarMotivo?descricao='+this.grupoSelecionado ;
    this.ajax.get(url)
      .subscribe((ident: ResponseNet) => {

        this.util.closeLoading();
        this.identificadores = ident.data;
        this.identicicadorSelecionado =  apt.iD_IDENTIFICADOR;
      }, err => {
        
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
    //this.identicicadorSelecionado = apt.iD_IDENTIFICADOR;
  }

  excluir(apt) {
   console.log(apt);
    Swal.fire({
      title: 'Atenção',
      text: 'Deseja Realmente Excluir esse Apontamento?',
      type: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
    }).then((willDelete) => {

      if (willDelete.dismiss) {
        //Swal.fire('', 'Your imaginary file is safe!', 'error');
      } else {



        let url = API + 'ApontamentoPerda/ApontamentoPerda/ExcluirPerda';
        debugger
        let dados = {
          "iD_APONTAMENTO_PERDA": apt.iD_APONTAMENTO_PERDA
        };

        this.util.showLoading();
        this.ajax.post(url, dados)
          .subscribe((op: ResponseNet) => {

            this.limpar();
            this.util.closeLoading();
            this.util.toasterShowSuccess("Sucesso", "Lancamento exluido com sucesso!");
            this.carreagarApontamentos();

          }, err => {
            debugger
            this.util.closeLoading();
            this.util.toasterShowError("Atenção", err.error);
          });

      }
    });
  }

  buscarOperador(){
    this.login.getUser();
    this.util.showLoading();
    var url = API + 'Operador/Operador?codigo='+this.login.user.turnoCodigo;;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.operadores = apt.data;  
        console.log(this.operadores);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }


  selecionarTipo(){
    console.log(this.tipo);
  }

  carreagarApontamentos(){
    this.util.showLoading();
    var url = API + 'ApontamentoPerda/ApontamentoPerda/buscaPerdas?ID_MONTAGEM_TURNO='+ this.id_montagem_turno;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.apontamentos = apt.data;  

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  validar(): boolean {

    if (this.tipo == undefined || this.tipo == "") {
      this.util.toasterShowError("Erro", "O Campo Tipo não pode ser vazio");
      return false;
    }

    if (this.quantidade <= 0) {
      this.util.toasterShowError("Erro", "O Campo Quantidade não pode ser 0");
      return false;
    }

    if (this.peso <= 0) {
      this.util.toasterShowError("Erro", "O Campo Peso não pode ser 0");
      return false;
    }

    if (this.identicicadorSelecionado == undefined ||this.identicicadorSelecionado == "") {
      this.util.toasterShowError("Erro", "O Campo Identificador não pode ser vazio");
      return false;
    }

    if (this.operadorSelecionado == undefined) {
      this.util.toasterShowError("Erro", "O Campo Operador não pode ser vazio");
      return false;
    }

    return true;
  }


  
  salvar() {
    this.login.getUser();
    if (this.validar()) {
      let url = API + 'ApontamentoPerda/ApontamentoPerda/SalvarAscync';
      let dados = {
        "id_montagem_turno": this.id_montagem_turno,
        "quantidade": this.quantidade,
        "peso": this.peso,
        "operador": this.operadorSelecionado,
        "observacao": this.observacao,
        "tipo": this.tipo,
        "id_perda_refugo": parseInt(this.identicicadorSelecionado),
        "USUARIO_AD": this.login.user.login
      };

      this.util.showLoading();
      this.ajax.post(url, dados)
        .subscribe((op: ResponseNet) => {

          this.limpar();
          this.util.closeLoading();
          this.util.toasterShowSuccess("Sucesso", "Lancamento de Perda feito com sucesso!");
          this.carreagarApontamentos();

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", err.message);
        });
    }
    //console.log(this.observacao);
  }

  limpar() {
    this.quantidade = 0;
    this.peso = 0;
    this.nome_operador = "";
    this.hora = new Date().getHours().toString() + " : " + new Date().getMinutes().toString();
    this.observacao = "";
    this.ehEditar = false;
  }

  buscaIdentificador() {
    this.util.showLoading();
    var url = API + 'PerdaRefugo/PerdaRefugo/BuscarIdentificadores';
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.grupos = apt.data;
        //this.identicicadorSelecionado = 1;
      }, err => {
        
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  buscaGrupoIdentificador(identificador){
    console.log(identificador);

    this.util.showLoading();
    var url = API + 'PerdaRefugo/PerdaRefugo/BuscarMotivo?descricao='+identificador;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.identificadores = apt.data;
        //this.identicicadorSelecionado = 1;
      }, err => {
        
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  /*buscaIdentificador(){
    this.util.showLoading();
    var url = API + 'PerdaRefugo/PerdaRefugo/BuscarIdentificadores';
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.identificadores = apt.data;  
        //this.identicicadorSelecionado = 1;
      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }*/

  voltar(){
    this.router.navigate(['/ordem-producao',  this.id_montagem_turno, this.nome, this.maquina]);
  }

}
