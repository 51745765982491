import { Time } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';
import Swal from 'sweetalert2';


@Component({
  selector: 'app-parado',
  templateUrl: './parado.component.html',
  styleUrls: ['./parado.component.scss']
})
export class ParadoComponent implements OnInit {

  id_montagem_turno: any;
  apontamentos: any;
  public nome: string;
  public maquina: string;

  public operadores: any;
  public operadorSelecionado: any;
  public apontamentoSelecionado: any;

  public inicio: any;
  public fim: any;
  public grupoSelecionado: any;
  public identicicadorSelecionado: any;
  public identificadores: any;
  public grupos: any;
  public nome_operador: string;
  public observacao: string;

  public vaiEditar: boolean;
  public editargrupo: string;
  public editarIdentificador: string;
  
  constructor(
    private activatedRoute: ActivatedRoute,
    private router: Router,
    private ajax: AjaxService,
    private util: UtilService,
    private login: LoginService
  ) {
    this.vaiEditar = false;
  }

  ngOnInit() {
    this.id_montagem_turno = parseInt(this.activatedRoute.snapshot.params['ordem']);
    this.nome = this.activatedRoute.snapshot.params['nome'];
    this.maquina = this.activatedRoute.snapshot.params['maquina'];
    this.buscaIdentificador();
    this.carreagarApontamentos();
    this.buscarOperador();
  }

  atualizar(){
    if(this.fim != undefined){
      console.log(this.apontamentoSelecionado);
      let url = API + 'ApontamentoParada/ApontamentoParada/AtualizarAscync';
      debugger
        let dados = {
          "fim": this.fim,
          "iD_APONTAMENTO_PARADA": parseInt(this.apontamentoSelecionado.iD_APONTAMENTO_PARADA),
        };
  
        this.util.showLoading();
        this.ajax.post(url, dados)
          .subscribe((op: ResponseNet) => {
  
            this.limpar();
            this.util.closeLoading();
            this.util.toasterShowSuccess("Sucesso", "Lancamento de Maquina Parada feito com sucesso!");
            this.carreagarApontamentos();
  
          }, err => {
            debugger
            this.util.closeLoading();
            this.util.toasterShowError("Atenção", err.error);
          });
    }
    else{
      this.util.toasterShowError("Erro", "O Campo Fim não pode ser Vazio");
    }
    
  }

  editar(apontamento){
    debugger
    this.vaiEditar = true;
    console.log(apontamento);
    this.apontamentoSelecionado = apontamento;
    this.inicio = apontamento.inicio;
    this.editarIdentificador = apontamento.identificador;
    this.nome_operador = apontamento.operador;
    this.observacao = apontamento.observacao;
  }

  excluir(apt){

    Swal.fire({
      title: 'Atenção',
      text: 'Deseja Realmente Excluir esse Apontamento?',
      type: 'warning',
      showCloseButton: true,
      confirmButtonText: 'Sim',
      showCancelButton: true,
      cancelButtonText: 'Não',
    }).then((willDelete) => {
 
        if (willDelete.dismiss) {
          //Swal.fire('', 'Your imaginary file is safe!', 'error');
        } else {
          


            let url = API + 'ApontamentoParada/ApontamentoParada/ExcluirApontamentoAscync';
            debugger
            let dados = {
              "iD_APONTAMENTO_PARADA": apt.iD_APONTAMENTO_PARADA
            };
      
            this.util.showLoading();
            this.ajax.post(url, dados)
              .subscribe((op: ResponseNet) => {
      
                this.limpar();
                this.util.closeLoading();
                this.util.toasterShowSuccess("Sucesso", "Lancamento exluido com sucesso!");
                this.carreagarApontamentos();
      
              }, err => {
                debugger
                this.util.closeLoading();
                this.util.toasterShowError("Atenção", err.error);
              });  

        }
      });
  }

  buscarOperador(){
    this.login.getUser();
    this.util.showLoading();
    var url = API + 'Operador/Operador?codigo='+this.login.user.turnoCodigo;;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.operadores = apt.data;  
        console.log(this.operadores);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  carreagarApontamentos() {
    this.util.showLoading();
    var url = API + 'ApontamentoParada/ApontamentoParada/buscaParadas?ID_MONTAGEM_TURNO=' + this.id_montagem_turno;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();        
        this.apontamentos = apt.data;
        this.vaiEditar = false;

      }, err => {        
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  buscaIdentificador() {
    this.util.showLoading();
    var url = API + 'Parada/Parada/BuscarIdentificadores';
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.grupos = apt.data;
        //this.identicicadorSelecionado = 1;
      }, err => {
        
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  buscaGrupoIdentificador(identificador){
    console.log(identificador);

    this.util.showLoading();
    var url = API + 'Parada/Parada/BuscarMotivo?descricao='+identificador;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.identificadores = apt.data;
        //this.identicicadorSelecionado = 1;
      }, err => {
        
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

  selectOption(selecionado){
    debugger
    console.log(selecionado);
  }

  validar(): boolean {

    if (this.inicio == undefined) {
      this.util.toasterShowError("Erro", "O Campo Inicio não pode ser Vazio");
      return false;
    }

    /*if (this.fim == undefined) {
      this.util.toasterShowError("Erro", "O Campo Fim não pode ser Vazio");
      return false;
    }*/

    debugger
    if (this.identicicadorSelecionado == undefined || this.identicicadorSelecionado == "") {
      this.util.toasterShowError("Erro", "O Campo Identificador não pode ser Vazio");
      return false;
    }

    if (this.operadorSelecionado == undefined) {
      this.util.toasterShowError("Erro", "O Campo Operador não pode ser vazio");
      return false;
    }

    debugger
    if (this.observacao == undefined || this.observacao == "") {
      this.util.toasterShowError("Erro", "O Campo Observação não pode ser Vazio");
      return false;
    }

    return true;
  }

  salvar() {
    this.login.getUser();    
    if (this.validar()) {
      let url = API + 'ApontamentoParada/ApontamentoParada/SalvarAscync';

      let dados = {
        "id_montagem_turno": this.id_montagem_turno,
        "inicio": this.inicio,
        "fim": this.fim,
        "operador": this.operadorSelecionado,
        "observacao": this.observacao,
        "id_parada": parseInt(this.identicicadorSelecionado),
        "USUARIO_AD": this.login.user.login
      };

      this.util.showLoading();
      this.ajax.post(url, dados)
        .subscribe((op: ResponseNet) => {

          this.limpar();
          this.util.closeLoading();
          this.util.toasterShowSuccess("Sucesso", "Lancamento de Maquina Parada feito com sucesso!");
          this.carreagarApontamentos();

        }, err => {
          debugger
          this.util.closeLoading();
          this.util.toasterShowError("Atenção", err.error);
        });
    }
  }

  limpar() {
    this.nome_operador = "";
    this.identicicadorSelecionado = "";
    this.grupoSelecionado = "";
    this.observacao = "";
  }

  voltar() {
    this.router.navigate(['/ordem-producao', this.id_montagem_turno, this.nome, this.maquina]);
  }

}
