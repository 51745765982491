
<div class="auth-wrapper">
    <div class="auth-content">
      <div class="card">
        <div class="row align-items-center text-center">
          <div class="col-md-12">
            <div class="card-body">
              <img src="https://assets.izap.com.br/antaresplasticos.com.br/plus/images?src=tema/plusfiles/2_Antares_plasticos.jpg&amp;mode=max&amp;width=180" alt="" class="img-fluid mb-4">
              <h4 class="mb-3 f-w-400">Logar</h4>
              <div class="input-group mb-3">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="feather icon-mail"></i></span>
                </div>
                <input type="text" class="form-control"  [(ngModel)]="login" name="login"  placeholder="Login">
              </div>
              <div class="input-group mb-4">
                <div class="input-group-prepend">
                  <span class="input-group-text"><i class="feather icon-lock"></i></span>
                </div>
                <input type="password" class="form-control"  [(ngModel)]="senha" name="senha"  placeholder="Senha">
              </div>
              <button class="btn btn-block btn-primary mb-4" (click)="Entrar()">ENTRAR</button>
              
            </div>
            <img *ngIf="loading" src="assets/images/loading.gif" alt="" class="img-fluid mb-4">
          </div>
        </div>
      </div>
    </div>
  </div>
  