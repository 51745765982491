<ul class="navbar-nav ml-auto">
  <li>
    <div class="dropdown" ngbDropdown placement="auto">
      <a ngbDropdownToggle href="javascript:" data-toggle="dropdown"> <i class="icon feather icon-user"> 
        
        {{nomeUsuario}} </i>  &nbsp;&nbsp; - {{cargo}} &nbsp;&nbsp; 

        
        
      </a>
      &nbsp;&nbsp;&nbsp;&nbsp; &nbsp;&nbsp;&nbsp;&nbsp;
      <a ngbDropdownToggle href="javascript:" (click)="Sair()" data-toggle="dropdown"> <i class="icon feather icon-x-circle"> 
        
        Sair </i>  

        
        
      </a>

    </div>
  </li>
  <!--<li (click)="this.visibleUserList = !this.visibleUserList;">
    <div class="dropdown">
      <a href="javascript:" class="displayChatbox dropdown-toggle">
        <i class="icon feather icon-mail"></i><span class="badge bg-success"><span class="sr-only"></span></span>
      </a>
    </div>
  </li>
  <li>
    <div class="dropdown drp-user" ngbDropdown placement="auto">
      <a href="javascript:" ngbDropdownToggle data-toggle="dropdown">
        <img src="assets/images/user/avatar-1.jpg" class="img-radius wid-40" alt="User-Profile-Image">
      </a>
      <div class="dropdown-menu dropdown-menu-right profile-notification" ngbDropdownMenu>
        <div class="pro-head">
          <img src="assets/images/user/avatar-1.jpg" class="img-radius" alt="User-Profile-Image">
          <span>John Doe</span>
          <a href="javascript:" class="dud-logout" title="Logout">
            <i class="feather icon-log-out"></i>
          </a>
        </div>
        <ul class="pro-body">
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-user"></i> Profile</a></li>
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-mail"></i> My Messages</a></li>
          <li><a href="javascript:" class="dropdown-item"><i class="feather icon-lock"></i> Lock Screen</a></li>
        </ul>
      </div>
    </div>
  </li>-->
</ul>

<section class="header-user-list" *ngIf="visibleUserList && !this.gradientConfig['rtl-layout']" [ngClass]="{'open': visibleUserList}" [@slideInOutLeft]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)" (onChatCollapse)="this.visibleUserList = !this.visibleUserList; this.chatMessage = false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage && !this.gradientConfig['rtl-layout']" [ngClass]="{'open': chatMessage}" [@slideInOutLeft]>
  <app-chat-msg (onChatToggle)="this.chatMessage = !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>

<section class="header-user-list" *ngIf="visibleUserList && this.gradientConfig['rtl-layout']" [ngClass]="{'open': visibleUserList}" [@slideInOutRight]>
  <app-chat-user-list (onChatToggle)="onChatToggle($event)" (onChatCollapse)="this.visibleUserList = !this.visibleUserList; this.chatMessage = false;"></app-chat-user-list>
</section>

<section class="header-chat" *ngIf="chatMessage && this.gradientConfig['rtl-layout']" [ngClass]="{'open': chatMessage}" [@slideInOutRight]>
  <app-chat-msg (onChatToggle)="this.chatMessage = !this.chatMessage" [friendId]="friendId"></app-chat-msg>
</section>
