<!--<div class="row">

      <div class="col-xl-3 col-md-6">
        <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-blue">
          <div class="row align-items-center m-b-0">
            <div class="col">
              <h6 class="m-b-5 text-white">Ordens <br>Iniciadas</h6>
              <h3 class="m-b-0 text-white">15,830</h3>
            </div>
            <div class="col-auto">
              <i class="fas fa-database text-white"></i>
            </div>
          </div>
        </app-card>
      </div>
      <div class="col-xl-3 col-md-6">
        <app-card [hidHeader]="true" cardClass="prod-p-card bg-c-green">
          <div class="row align-items-center m-b-0">
            <div class="col">
              <h6 class="m-b-5 text-white">Ordens <br>concluidas</h6>
              <h3 class="m-b-0 text-white">6,784</h3>
            </div>
            <div class="col-auto">
              <i class="fas fa-tags text-white"></i>
            </div>
          </div>
        </app-card>
      </div>

  </div>-->
<div class="row">
  <!--<div class="col-xl-6">
        <app-card cardTitle="Ordens Produção" cardClass="feed-card"   blockClass="scrollable-body">
          <perfect-scrollbar [style.max-height]="'385px'">
            <div class="row m-b-25 align-items-center">
              <div class="col-auto p-r-0">
                <i class="feather icon-shopping-cart badge-light-primary feed-icon"></i>
              </div>
              <div class="col">
                <a href="javascript:">
                  <h6 class="m-b-5">O.P 0001556 com perda Acima do esperado
                      <span class="text-muted float-right f-14">13/05/2020</span>
                    </h6>
                </a>
              </div>
            </div>
            <div class="row m-b-25 align-items-center">
                <div class="col-auto p-r-0">
                  <i class="feather icon-shopping-cart badge-light-primary feed-icon"></i>
                </div>
                <div class="col">
                  <a href="javascript:">
                    <h6 class="m-b-5">O.P 0005646 com perda Acima do esperado
                        <span class="text-muted float-right f-14">20/05/2020</span>
                      </h6>
                  </a>
                </div>
              </div>            


          </perfect-scrollbar>
        </app-card>
      </div>-->
  <div class="col-xl-12">
    <app-card cardTitle="Máquinas Paradas" cardClass="feed-card" blockClass="scrollable-body">
      <perfect-scrollbar [style.max-height]="'385px'">
        <div class="row m-b-25 align-items-center" *ngFor="let maqPar of maquinaParadas">
          <div class="col-auto p-r-0">
            <i class="feather icon-bell badge-light-danger feed-icon"></i>
          </div>
          <div class="col">
            <a href="javascript:">
              <h6 class="m-b-5">Maquina {{maqPar.h1_CODIGO}}-{{maqPar.h1_DESCRI}} parada Motivo: {{maqPar.motivo}}
                <span class="text-muted float-right f-14">{{maqPar.horA_INICIO | date : "dd/MM/yy hh:mm"}} </span>
              </h6>
            </a>
          </div>
        </div>


      </perfect-scrollbar>
    </app-card>
  </div>


</div>
<div class="row">
  <div class="col-xl-6">
    <app-card cardTitle="Máquinas Ociosas" cardClass="feed-card" blockClass="scrollable-body">
      <perfect-scrollbar [style.max-height]="'385px'">

        <div class="row m-b-25 align-items-center" *ngFor="let maqOc of maquinaOciosas">
          <div class="col-auto p-r-0">
            <i class="feather icon-bell badge-light-success  feed-icon"></i>
          </div>
          <div class="col">
            <a href="javascript:">
              <h6 class="m-b-5">Maquina {{maqOc.h1_CODIGO}} - {{maqOc.h1_DESCRI}}

              </h6>
            </a>
          </div>
        </div>

      </perfect-scrollbar>
    </app-card>
  </div>
  <div class="col-xl-6">
    <app-card cardTitle="Máquinas Em Funcionamento" cardClass="feed-card" blockClass="scrollable-body">
      <perfect-scrollbar [style.max-height]="'385px'">

        <div class="row m-b-25 align-items-center" *ngFor="let maqFun of maquinaFuncionando">
          <div class="col-auto p-r-0">
            <i class="feather icon-bell badge-light-success  feed-icon"></i>
          </div>
          <div class="col">
            <a href="javascript:">
              <h6 class="m-b-5">Maquina {{maqFun.h1_CODIGO}} - {{maqFun.h1_DESCRI}}

              </h6>
            </a>
          </div>
        </div>

      </perfect-scrollbar>
    </app-card>
  </div>

</div>
<div class="row">
  <div class="col-xl-12 col-md-12">
    <app-card cardTitle="Previsto X Realizados" cardClass="table-card">
      <perfect-scrollbar [style.max-height]="'325px'">
        <div class="table-responsive">
          <table class="table table-hover m-b-0">
            <thead>
              <tr>
                <th>Programacao</th>
                <th>Numero OP</th>
                <th>Previsto</th>
                <th>Realizado</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let prev of previstoXRealizados">
                <td>{{prev.zW0_CODIGO}}</td>
                <td>{{prev.zW0_OP}} </td>
                <td> {{prev.total }} </td>
                <td> {{prev.lancados}} </td>
              </tr>

            </tbody>
          </table>
        </div>
      </perfect-scrollbar>
    </app-card>
  </div>
  <div class="col-xl-12 col-md-12">
    <app-card cardTitle="Peso Médio Produtos" cardClass="table-card">
      <perfect-scrollbar [style.max-height]="'325px'">
        <div class="table-responsive">
          <table class="table table-hover m-b-0">
            <thead>
              <tr>
                <th>Produto</th>
                <th>Numero OP</th>
                <th>Peso Médio</th>

              </tr>
            </thead>
            <tbody>
              <tr *ngFor="let pesoM of pesoMedioProdutos">
                <td>{{pesoM.b1_DESC}}</td>
                <td>{{pesoM.c2_NUM}} {{pesoM.numerO_OP_AVULSA}} </td>
                <td> {{pesoM.mediaOrdem | number:'.4-4'}} </td>
              </tr>

            </tbody>
          </table>
        </div>
      </perfect-scrollbar>
    </app-card>
  </div>
</div>
