
import { OpComponent } from './pages/relatorio/op/op.component';
import { LoginComponent } from './pages/login/login.component';
import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { HomeComponent } from './pages/home/home.component';
import { InclusaoApontamentoComponent } from './pages/inclusao-apontamento/inclusao-apontamento.component';
import { InicioApontamentoNoTurnoComponent } from './pages/inicio-apontamento-no-turno/inicio-apontamento-no-turno.component';
import { ManutencaoApontamentosComponent } from './pages/manutencao-apontamentos/manutencao-apontamentos.component';
import { ManutencaoEncerramentoComponent } from './pages/manutencao-encerramento/manutencao-encerramento.component';
import { OrdemProducaoComponent } from './pages/ordem-producao/ordem-producao.component';
import { ParadoComponent } from './pages/ordem-producao/parado/parado.component';
import { PerdaComponent } from './pages/ordem-producao/perda/perda.component';
import { PesoMedioComponent } from './pages/ordem-producao/peso-medio/peso-medio.component';
import { ProdutoAcabadoComponent } from './pages/ordem-producao/produto-acabado/produto-acabado.component';
import { AdminComponent } from './theme/layout/admin/admin.component';
import {AuthComponent} from './theme/layout/auth/auth.component';
import { LoggedInGuard } from './pages/security/loggedin.quard';
import { ProgramadoComponent } from './pages/relatorio/programado/programado.component';
import { RealizadoComponent } from './pages/relatorio/realizado/realizado.component';
import { GeralComponent } from './pages/relatorio/geral/geral.component';

const routes: Routes = [
  {
    path: '',
    component: AdminComponent,
    children: [
      {
        path: '',
        redirectTo: 'home',
        pathMatch: 'full'
      },
      {
        path: 'sample-page',
        loadChildren: () => import('./demo/pages/sample-page/sample-page.module').then(module => module.SamplePageModule)
      },
      { path: 'home',   component: HomeComponent,  canLoad: [LoggedInGuard], canActivate:[LoggedInGuard] }
      ,{ path: 'manutencao-apontamentos',   component: ManutencaoApontamentosComponent,  canLoad: [LoggedInGuard], canActivate:[LoggedInGuard]  }
      ,{ path: 'manutencao-encerramentos',   component: ManutencaoEncerramentoComponent,  canLoad: [LoggedInGuard], canActivate:[LoggedInGuard] }
      ,{ path: 'inclusao-apontamento',   component: InclusaoApontamentoComponent , canLoad: [LoggedInGuard], canActivate:[LoggedInGuard]}
      ,{ path: 'inicio-apontamentos-no-turno',   component: InicioApontamentoNoTurnoComponent, canLoad: [LoggedInGuard], canActivate:[LoggedInGuard]}
      ,{ path: 'ordem-producao/:ordem/:nome/:maquina',   component: OrdemProducaoComponent, canLoad: [LoggedInGuard], canActivate:[LoggedInGuard] }
      ,{ path: 'peso-medio/:ordem/:nome/:maquina',   component: PesoMedioComponent,canActivate:[LoggedInGuard],canLoad: [LoggedInGuard]  }
      ,{ path: 'produto-acabado/:ordem/:nome/:maquina',   component: ProdutoAcabadoComponent, canLoad: [LoggedInGuard], canActivate:[LoggedInGuard] }
      ,{ path: 'parado/:ordem/:nome/:maquina',   component: ParadoComponent, canLoad: [LoggedInGuard], canActivate:[LoggedInGuard] }
      ,{ path: 'perda/:ordem/:nome/:maquina',   component: PerdaComponent, canLoad: [LoggedInGuard], canActivate:[LoggedInGuard]}
      ,{ path: 'relatorio-op',   component: OpComponent,  canLoad: [LoggedInGuard], canActivate:[LoggedInGuard]  }
      ,{ path: 'relatorio-programado',   component: ProgramadoComponent,  canLoad: [LoggedInGuard], canActivate:[LoggedInGuard]  }
      ,{ path: 'relatorio-realizado',   component: RealizadoComponent,  canLoad: [LoggedInGuard], canActivate:[LoggedInGuard]  }
      ,{ path: 'relatorio-geral',   component: GeralComponent,  canLoad: [LoggedInGuard], canActivate:[LoggedInGuard]  }

    ]
  },
  {
    path: '',
    component: AuthComponent,
    children: [
      {
         path: 'login',   component: LoginComponent  
      }
    ]
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
