import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';


export const HTTP_OPTIONS = {
  headers: new HttpHeaders({
    'Content-Type':  'application/json',
    'LumagoKey': 'ab1b46a7-2f9a-416b-b0c1-ec67ad19cf96',
    'Access-Control-Allow-Credentials' : 'false',
    'Access-Control-Allow-Origin': '*',
    'Access-Control-Allow-Methods': 'GET, POST, PATCH, DELETE, PUT, OPTIONS',
    'Access-Control-Allow-Headers': 'Content-Type, Access-Control-Allow-Headers, Authorization, X-Requested-With',
  })
};

@Injectable({
  providedIn: 'root'
})



export class AjaxService {

  public headers: any;
  private options: any;
  
  constructor(
    private http: HttpClient
  ) { }

  post(url: string, data: any): any {
    this.headers = HTTP_OPTIONS;
    //this.headers = new HttpHeaders()
      //.set('Content-Type', 'application/json')
      //.set('LumagoKey', 'ab1b46a7-2f9a-416b-b0c1-ec67ad19cf96');
    this.options = {
      headers: this.headers
    };

    return this.http.post<any>(url, data, this.options);
  }

  get(url): any {
    
    this.headers = HTTP_OPTIONS;
      //.set('Content-Type', 'application/x-www-form-urlencoded;charset=UTF-8')
      //.set('LumagoKey', 'ab1b46a7-2f9a-416b-b0c1-ec67ad19cf96');
    
    
    
      this.options = {
      headers: this.headers
    };
    return this.http.get<any>(url, this.options);
  }
}
