import { Component, OnInit } from '@angular/core';
import {NgbCalendar, NgbDateParserFormatter, NgbDateStruct} from '@ng-bootstrap/ng-bootstrap';
import {ColorPickerService, Rgba} from 'ngx-color-picker';
import { API } from 'src/app/app.api';
import { ResponseNet } from 'src/app/model/ResponseNet.model';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';
import { UtilService } from 'src/app/service/util.service';


const now = new Date();

@Component({
  selector: 'app-op',
  templateUrl: './op.component.html',
  styleUrls: ['./op.component.scss']
})
export class OpComponent implements OnInit {

  modelPopup: NgbDateStruct;
  dataInicio: NgbDateStruct;
  dataFim: NgbDateStruct;
  public query: string;
  public ops: any;

  constructor(
    private ajax: AjaxService,
    private util: UtilService,
    private login: LoginService
  ) { }

  ngOnInit() {
    this.selectToday();
  }


  selectToday() {
    this.modelPopup = {year: now.getFullYear(), month: now.getMonth() + 1, day: now.getDate()};
    this.dataInicio = this.dataFim = this.modelPopup;
  }

  imprimirExcel(){
    
    let dtInicio = this.dataInicio.year+'-'+this.dataInicio.month+'-'+this.dataInicio.day;
    let dtFim = this.dataFim.year+'-'+this.dataFim.month+'-'+this.dataFim.day;
    this.query = this.query == undefined ? "" : this.query;
    var url = API + 'Op/Op/ImprimirRelatorioOpExcel?dtInicio='+dtInicio+'&dtFim='+dtFim;
    window.open(url);
  }

  buscarOps(){
    
    let dtInicio = this.dataInicio.year+'-'+this.dataInicio.month+'-'+this.dataInicio.day;
    let dtFim = this.dataFim.year+'-'+this.dataFim.month+'-'+this.dataFim.day;

    //let dtInicio = '2021-03-23';
    //let dtFim = '2021-03-24';

    this.util.showLoading();
    var url = API + 'Op/Op/GetRelatorioOp?dtInicio='+dtInicio+'&dtFim='+dtFim;
    this.ajax.get(url)
      .subscribe((apt: ResponseNet) => {

        this.util.closeLoading();
        this.ops = apt.data;  
        console.log(this.ops);

      }, err => {
        debugger
        this.util.closeLoading();
        this.util.toasterShowError("Atenção", err.message);
      });
  }

}
