<ngx-spinner bdColor="rgba(51,51,51,0.8)" size="medium" color="#fff" type="ball-scale-multiple">
    <p style="font-size: 20px; color: white">Aguarde ...</p>
  </ngx-spinner>
  <div class="row">
    <div class="col-sm-12">
      <app-card cardTitle="Filtros" [options]="false">
  
        <div class="col-sm-12 ">
          <div class="row">
            <div class="col-md-3 m-b-30">
              <h5>Inicio</h5>
              <hr>
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" autocomplete="off" placeholder="yyyy-mm-dd" name="dataInicio"
                    [(ngModel)]="dataInicio" ngbDatepicker [markDisabled]="false" #d="ngbDatepicker">
                  <div class="input-group-append">
                    <span class="input-group-text" (click)="d.toggle()">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-3 m-b-30">
  
              <h5>Fim</h5>
              <hr>
              <div class="form-group">
                <div class="input-group">
                  <input class="form-control" autocomplete="off" placeholder="yyyy-mm-dd" name="dataFim"
                    [(ngModel)]="dataFim" ngbDatepicker [markDisabled]="false" #e="ngbDatepicker">
                  <div class="input-group-append">
                    <span class="input-group-text" (click)="e.toggle()">
                      <i class="fa fa-calendar"></i>
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div class="col-md-6 m-b-30">
              <h5> &nbsp;&nbsp;</h5>
              <hr>
              <div class="form-group">
                <div class="input-group">
                  <button type="button" class="btn btn-warning" (click)="buscarProg()">
                    <i class="feather icon-search"></i>
                    &nbsp;&nbsp; Buscar</button>&nbsp;&nbsp;&nbsp;&nbsp;
                    <button type="button" class="btn btn-danger" (click)="imprimir()">
                      <i class="fa fa-file-pdf"></i>
                      &nbsp;&nbsp; Imprimir PDF</button>&nbsp;&nbsp;&nbsp;&nbsp;
                      <button type="button" class="btn btn-success" (click)="imprimirExcel()">
                        <i class="fa fa-file-excel"></i>
                        &nbsp;&nbsp; Imprimir XlS</button>
                </div>
              </div>

  
            </div>
          </div>
  
        </div>
      </app-card>
    </div>
  
    <div class="col-sm-12">
      <app-card cardTitle="Resultado" [options]="false">
  
        <div class="col-sm-12 ">
          <div class="row">
            <label for="exampleInputEmail1">Filtro</label>
            <input type="text" class="form-control" [(ngModel)]="query"
              placeholder="Digite seu filtro, sera feito uma busca em todas as colunas">
          </div> <br>
          <div class="row">
            <div class="table-responsive">
              <table class="table table-hover m-b-0">
                <thead>
                  <tr align="center">
                    <th>Data</th>
                    <th>Máquina</th>
                    <th>Prog</th>
                    <th>O.P.</th>
                    <th>Cod. Produto</th>
                    <th>Produto</th>
                    <th>Previsto</th>
                    <th>Realizado</th>
                    <th>%</th>
                    <th>Materia <br>Prima</th>
                    <th>Maquina <br>Parada</th>
                    <th>Tempo <br>Produção</th>
                    <th style="text-align: center"> SUCATA
                        <thead>
                           
                            <tr>
                              <th>Peça</th>
                              <th>B.C.V</th>
                            </tr>
                        </thead>
                    </th>
                    <th>Status</th>
                    <th>Turno</th>
  
                  </tr>
                </thead>
                <tbody>
  
                  <tr *ngFor="let op of progs  | fullTextSearch:query" align="center">
                    
                    <td><small>{{op.c2_EMISSAO | date: 'dd/MM/yyyy'}}</small></td>
                    <td> <small>{{op.h1_CODIGO}}-{{op.h1_DESCRI}}</small></td>
                    <td><small>{{op.programacao_codigo}}</small></td>
                    <td>
                      <h6 *ngIf="op.op != null" class="m-0"><small> {{op.op}}</small> </h6>
                      <h6 *ngIf="op.op == null" class="m-0"><small>{{op.op_avulsa}} </small></h6>
  
                    </td>
                    <td><small>{{op.c2_PRODUTO}}</small></td>
                    <td><small>{{op.b1_DESC | slice:0:50}}</small></td>
  
                    <td><small> {{op.c2_QUANT }} </small></td>
                    <td><small> {{op.lancados }} </small></td>
                    <td><small> {{op.lancados / op.c2_QUANT * 100 | number:'.2-2'}} %</small></td>
                    <td><small> {{op.materia_Prima | number:'.2-2'}} </small></td>
                    <td><small> {{op.maquina_Parada }} m</small></td>
                    <td><small> {{op.difMinutos}}</small></td>
                    <td><small> 
                        <table align="center">
                            
                            <tbody >
                                <td><small> {{op.sucata_Peca | number:'.2-2' }}  </small></td>
                                <td><small> {{op.sucata_B_C_V | number:'.2-2' }}</small></td>
                            </tbody>
                        </table>
                      
                    </small>
                </td>
                    <td>
                      <small>
                        <label *ngIf="op.status_op == 'ENCERRADA'" class="badge badge-light-danger"> {{op.status_op}}
                        </label>
                        <label *ngIf="op.status_op == 'EM PROCESSO'" class="badge badge-light-warning"> {{op.status_op}}
                        </label>
                        <label *ngIf="op.status_op == 'ABERTA'" class="badge badge-light-success"> {{op.status_op}}
                        </label>
                      </small>
                    </td>
                    <td><small> {{op.r6_DESC }} </small></td>
                  </tr>
  
  
  
                </tbody>
              </table>
            </div>
  
          </div>
  
        </div>
      </app-card>
    </div>
  
  </div>
  