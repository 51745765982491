import { Injectable } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { User } from '../model/user.model';
import 'rxjs/add/operator/do'
import 'rxjs/add/operator/filter'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class LoginService {

  user: User
  lastUrl: string

  

  constructor(private router: Router) {
    this.router.events.filter(e=> e instanceof NavigationEnd)
    .subscribe( (e: NavigationEnd) => this.lastUrl = e.url)
  }

  isLoggedIn(): boolean{
    this.getUser();
    return this.user !== null;
  }


  clearUser() {
    localStorage.removeItem("const_config");
  }

  setUser(user: User) {
    localStorage.setItem("const_config", JSON.stringify(user));
  }

  getUser(): User {
    this.user = JSON.parse(localStorage.getItem("const_config"));
    return this.user;
  }

  handleLogin(path: string = this.lastUrl) {
    this.router.navigate(['\login'])
  }

  logout() {
    this.clearUser();
    this.user = undefined;
    this.router.navigate(['\login'])

  }

  loginJson(cb) {
    const req = new XMLHttpRequest();
    req.open('GET', 'assets/data/login.json');

    req.onload = () => {
      cb(JSON.parse(req.response));
    };

    return req.send();
  }
}
