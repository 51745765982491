import { Injectable } from '@angular/core';
import { AjaxService } from 'src/app/service/ajax.service';
import { LoginService } from 'src/app/service/login.service';

export interface NavigationItem {
  id: string;
  title: string;
  type: 'item' | 'collapse' | 'group';
  translate?: string;
  icon?: string;
  hidden?: boolean;
  url?: string;
  classes?: string;
  exactMatch?: boolean;
  external?: boolean;
  target?: boolean;
  breadcrumbs?: boolean;
  function?: any;
  badge?: {
    title?: string;
    type?: string;
  };
  children?: Navigation[];
}

export interface Navigation extends NavigationItem {
  children?: NavigationItem[];
}

const NavigationItems = [
  {
    id: 'Home',
    title: 'HOME',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'home',
        title: 'Home',
        type: 'item',
        url: '/home',
        classes: 'nav-item',
        icon: 'feather icon-home'
      }
    ],

  },
  {
    id: 'producao',
    title: 'PRODUÇÃO',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'dasboard',
        title: 'Dasboard',
        type: 'item',
        url: '/home',
        classes: 'nav-item',
        icon: 'feather icon-layers'
      },
      {
        id: 'inicio-apontamentos-no-turno',
        title: 'Programação de Produção',
        type: 'item',
        url: '/inicio-apontamentos-no-turno',
        classes: 'nav-item',
        icon: 'feather icon-layers'
      },

      {
        id: 'inclusao-apontamento',
        title: 'Inclusão de Programação',
        type: 'item',
        url: '/inclusao-apontamento',
        classes: 'nav-item',
        icon: 'feather icon-save'
      },

      {
        id: 'manutencao-encerramentos',
        title: 'Encerramento',
        type: 'item',
        url: '/manutencao-encerramentos',
        classes: 'nav-item',
        icon: 'feather icon-x-circle'
      },
    ],

  },
  {
    id: 'APONTAMENTOS',
    title: 'APONTAMENTOS',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [

      {
        id: 'manutencao-apontamentos',
        title: 'Manutenção',
        type: 'item',
        url: '/manutencao-apontamentos',
        classes: 'nav-item',
        icon: 'feather icon-edit'
      }

    ]
  },
  {
    id: 'Relatorio',
    title: 'RELATÓRIOS',
    type: 'group',
    icon: 'feather icon-align-left',
    children: [
      {
        id: 'relatorio-geral',
        title: 'Geral',
        type: 'item',
        url: '/relatorio-geral',
        classes: 'nav-item',
        icon: 'feather icon-edit'
      },
      {
        id: 'relatorio-op',
        title: 'OP',
        type: 'item',
        url: '/relatorio-op',
        classes: 'nav-item',
        icon: 'feather icon-edit'
      },
      {
        id: 'relatorio-programado',
        title: 'Programação',
        type: 'item',
        url: '/relatorio-programado',
        classes: 'nav-item',
        icon: 'feather icon-edit'
      }      
    ],

  }
];

@Injectable()
export class NavigationItem {

  constructor(
    private login: LoginService,
    private ajax: AjaxService
  ) {

  }


  public verificaSeTemPermissao(idMenu: string) {

    let menu = this.login.getUser()["menu"];

    let valido = null;
    for (var i = 0; i < menu.length; i++) {
        if(menu[i].codigo == idMenu)
        {
          valido = menu[i];
          break;
        }
    }

    return valido;

  }

  public get() {
    
    let itens = NavigationItems;



    for (var i = 0; i < itens.length; i++) {

      for (var j = 0; j < itens[i].children.length; j++) {
        let children =  this.verificaSeTemPermissao(itens[i].children[j].id);
        
        //retira filhos
        if(children == null){
          itens[i].children.splice(j,1);
        }
      }
      
      //retira pai
      if(itens[i].children.length == 0){
        itens.splice(i,1);
      }

    }

    return itens;
  }
}
